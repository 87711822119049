require('./bootstrap');

$(document).ready(function(){
    $('#phone').mask("+00 00 00000-0000");

    $("#newsOn").on('change', function(){
        if($("#newsOff").is(':checked')){
            $('#newsOff').prop('checked', false);    
        }
    });

    $("#newsOff").on('change', function(){
        if($("#newsOn").is(':checked')){
            $('#newsOn').prop('checked', false);    
        }
    });

});